@import '../../styles/breakpoints';

.totalsSection {
  padding: var(--outside-gutters);
  display: flex;
  flex-direction: column;
}

.totaliserContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  align-self: stretch;
  flex-direction: row;

  @include device-small {
    flex-grow: 1;
  }

  @include device-large {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.totaliser {
  margin-right: var(--size-spacing-05);
  width: 98px;
}

@include device-large {
  .totaliser {
    margin: 0 auto var(--size-spacing-04);
    width: 148px;
  }
}

.label {
  background-color: var(--color-neutral-400);
  padding: var(--size-spacing-02) var(--size-spacing-03);
  border-radius: 4px;
  width: fit-content;

  @include device-large {
    align-self: center;
  }
}
